const Items = []
const LecturerItem = {
  id: '1',
  avatar: '/static/avatar/default.jpg',
  code: '',
  lu_id: '',
  user_name: '',
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  point: 0,
  rating: 0
}

import { fetchPost, fetchGet, defaultCatch } from './fetch'
const url = '/api/v1/lecturer'
const mapLecturer = (item) => {
  if (!item.avatar) {
    item.avatar = '/static/avatar/default.jpg'
  }
  item.name = `${item.first_name} ${item.last_name}`
  return item
}
const getLecturerById = (id) => {
  const apiUrl = `${url}/${id}`
  return fetchGet(apiUrl, {})
    .then((lecturer) => {
      return Promise.resolve(lecturer)
    })
    .catch(defaultCatch)
}

const getLecturer = (q, limit, offset) => {
  const apiUrl = `${url}`
  return fetchGet(apiUrl, { q, limit, offset })
    .then((lecturers) => {
      return Promise.resolve(lecturers.map(mapLecturer))
    })
    .catch(defaultCatch)
}

const newLecturer = (data) => {
  const apiUrl = `${url}/save`
  return fetchPost(apiUrl, data)
    .then((lecturer) => {
      const newlec = mapLecturer(Object.assign({}, LecturerItem, lecturer))
      return Promise.resolve(newlec)
    })
    .catch(defaultCatch)
}

const updateLecturer = (id, data) => {
  if (id === undefined) {
    return Promise.reject(new Error('Missing lecturer id'))
  }
  const apiUrl = `${url}/${id}/save`
  return fetchPost(apiUrl, data)
    .then((lecturer) => {
      return Promise.resolve(lecturer)
    })
    .catch((error) => {
      console.error(error)
      return Promise.reject(error)
    })
}

const deleteLecturer = (id) => {
  if (id === undefined) {
    return Promise.reject(new Error('Missing lecturer id'))
  }
  const urlById = `${url}/${id}/delete`
  return fetchPost(urlById, {})
    .then((lecturer) => {
      return Promise.resolve(lecturer)
    })
    .catch((error) => {
      console.error(error)
      return Promise.reject(error)
    })
}

const syncData = (apiKey) => {
  const apiUrl = `/api/v1/sync/lecturers`
  const headers = { Authorization: apiKey }
  return fetchGet(apiUrl, {}, headers)
    .then((fetched) => {
      return Promise.resolve(fetched)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export {
  Items,
  LecturerItem,
  getLecturer,
  getLecturerById,
  newLecturer,
  updateLecturer,
  deleteLecturer,
  syncData
}
