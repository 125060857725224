<template>
  <div class="list-table">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-subheader>หลักสูตร</v-subheader>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-toolbar flat color="white">
              <v-btn color="primary" @click="handleAddItem">เพิ่ม</v-btn>
              <v-btn
                color="success"
                class="ml-1"
                :loading="loading"
                :disabled="loading"
                @click="handleSync"
                >ซิงค์ข้อมูล LU</v-btn
              >
              <v-text-field
                id
                name
                flat
                prepend-icon="search"
                placeholder="ชื่อ-นามสกุล/อีเมล์"
                v-model="search"
                hide-details
                class="hidden-sm-and-down ml-1"
              ></v-text-field>
              <v-btn icon>
                <v-icon>filter_list</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="pa-0">
              <!-- Data table -->
              <v-data-table
                :headers="complex.headers"
                :search="search"
                :items="complex.items"
                :items-per-page-options="[
                  10,
                  25,
                  50,
                  { text: 'All', value: -1 }
                ]"
                class="elevation-1"
                item-key="id"
                v-model="complex.selected"
                @click:row="handleClick"
              >
                <template v-slot:item.point="{ item }">
                  <v-rating
                    v-model="item.point"
                    background-color="orange lighten-3"
                    color="orange"
                    readonly="true"
                    medium
                  ></v-rating>
                </template>
                <template v-slot:item.avatar="{ item }">
                  <v-avatar>
                    <img :src="item.avatar" alt="avatar" size="16" />
                  </v-avatar>
                </template>
                <template v-slot:item.action="{ item }">
                  <v-menu>
                    <template v-slot:activator="{ on: menu }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                          <v-btn icon v-on="{ ...menu }">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <span>Action</span>
                      </v-tooltip>
                    </template>
                    <v-list class="pa-0" dense>
                      <v-list-item
                        v-for="action in actions"
                        :key="action.text"
                        @click="action.click(item)"
                      >
                        <v-list-item-icon class="mr-2">
                          <v-icon small>{{ action.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ action.text }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialog" max-width="150vh" scrollable :loading="loading">
      <v-card>
        <v-toolbar card>รายละเอียดวิทยากร</v-toolbar>
        <v-card-text>
          <v-row>
            <v-col>
              <h3>ชื่อ-นามสกุล</h3>
              <p>{{ formModel.name }}</p>
              <h3>อีเมล์</h3>
              <p>{{ formModel.email }}</p>
              <h3>คะแนน</h3>
              <v-rating
                v-model="formModel.point"
                background-color="orange lighten-3"
                color="orange"
                readonly="true"
                medium
              ></v-rating>
              <h3>กิจกรรมบรรยาย</h3>
              <ul id="example-1">
                <li v-for="item in formModel.activities" :key="item.id">
                  <v-switch
                    v-model="disabled"
                    class="ma-2"
                    :label="`${item.title}`"
                  ></v-switch>
                  {{ item.id }}
                </li>
              </ul>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialog = false">
            ปิด
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogFormLecturer" max-width="150vh">
      <v-card>
        <v-toolbar card>เพิ่ม/แก้ไข วิทยากร</v-toolbar>
        <v-card-text>
          <form>
            <v-text-field
              v-model="formModel.first_name"
              :counter="50"
              label="ชื่อ"
              required
            ></v-text-field>
            <v-text-field
              v-model="formModel.last_name"
              :counter="50"
              label="นามสกุล"
              required
            ></v-text-field>
            <v-text-field
              v-model="formModel.email"
              label="อีเมล์"
              required
            ></v-text-field>
            <v-divider class="mt-3 mb-3"></v-divider>
            <v-btn :loading="loading" :disabled="loading" @click="handleSubmit"
              >บันทึก</v-btn
            >
          </form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Util from '@/util'
import {
  LecturerItem,
  getLecturer,
  getLecturerById,
  newLecturer,
  updateLecturer,
  deleteLecturer,
  syncData
} from '@/api/lecturer'

export default {
  name: 'LecturerList',
  data() {
    return {
      loading: false,
      editMode: false,
      formModel: LecturerItem,
      dialog: false,
      dialogFormLecturer: false,
      search: '',
      complex: {
        selected: [],
        headers: [
          {
            text: '#',
            value: 'id'
          },
          {
            text: 'ชื่อ-นามกุล',
            value: 'name'
          },
          {
            text: 'อีเมล์',
            value: 'email'
          },
          {
            text: 'คะแนน',
            value: 'point'
          },
          {
            text: 'จัดการ',
            value: 'action'
          }
        ],
        items: []
      },
      actions: [
        {
          text: 'รายละเอียด',
          icon: 'mdi-eye',
          click: this.handleViewItem
        },
        // {
        //   text: 'ผลการประเมิน',
        //   icon: 'mdi-chart-areaspline',
        //   click: this.handleReportItem
        // },
        {
          text: 'แก้ไข',
          icon: 'mdi-pencil',
          click: this.handleEditItem
        },
        {
          text: 'ลบ',
          icon: 'mdi-close',
          click: this.handleDeleteItem
        }
      ]
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    ...mapGetters(['getLoginUser'])
  },
  mounted() {
    this.loginUser = this.getLoginUser
  },
  methods: {
    fetchData() {
      this.loading = true
      getLecturer('', 100, 0)
        .then((lecturers) => {
          this.complex.items = lecturers
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleSync() {
      this.loading = true
      syncData(this.loginUser.lu_token)
        .then((synced) => {
          Util.syncedDialog(this.$alert, synced)
          this.fetchData()
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleSubmit() {
      this.loading = true
      if (this.editMode) {
        updateLecturer(this.formModel.id, this.formModel)
          .then(() => {
            this.$notify({
              type: 'info',
              group: 'notify',
              text: 'แก้ไขข้อมูลเรียบร้อยแล้ว'
            })
            this.dialogFormLecturer = false
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        newLecturer(this.formModel)
          .then((lecturer) => {
            this.complex.items.push(lecturer)
            this.$notify({
              type: 'info',
              group: 'notify',
              text: 'เพิ่มข้อมูลเรียบร้อยแล้ว'
            })
            this.dialogFormLecturer = false
          })
          .finally(() => {
            this.loading = false
          })
      }
    },

    handleClick(row) {
      this.handleViewItem(row)
    },
    handleAddItem() {
      this.loading = false
      this.editMode = false
      this.formModel = {}
      this.dialogFormLecturer = true
    },
    handleViewItem(row) {
      this.dialog = true
      this.loading = true
      this.formModel = Object.assign(this.formModel, row)
      getLecturerById(row.id).then((lecturer) => {
        this.formModel.activities = lecturer
      })
    },
    // handleReportItem(row) {
    //   this.$router.push(`/lecturer/${row.id}/report`)
    // },
    handleEditItem(row) {
      Util.goToLuSystem(
        this.loginUser.lu_token,
        `/admin/personnels/${row.lu_id}/edit`
      )
      this.formModel = row
      this.editMode = true
      this.dialogFormGroup = true
    },
    handleDeleteItem(row) {
      console.log(row)
      this.$confirm({
        title: 'ยืนยันการลบข้อมูล',
        message: 'คุณต้องการลบข้อมูลนี้ใช่หรือไม่',
        button: {
          yes: 'ใช่',
          no: 'ยกเลิก'
        },
        callback: () => {
          deleteLecturer(row.id)
            .then((lecturer) => {
              this.fetchData()
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>
